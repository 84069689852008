import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <footer class="pt-lg-8 pt-5 footer primary-gradient">
        <div class="container mt-lg-3">
          <div class="row my-2">
            <div class="col-lg-4 col-md-6 col-12">
              <div class="mb-4">
                <img src="/assets/images/icon.png" height="80" alt="Logo" />
                <div class="mt-4 text-white">
                  <p>
                    With the vision of reaching the common masses and creating
                    awareness about vaidic and modern education, a leap was
                    taken and in the capital city of Jharkhand, Ranchi.
                  </p>
                  <p>
                    Acharyakulam is an Educational Institution which blends
                    Vedic Wisdom with Modern Education.
                  </p>
                  <div class="fs-4 mt-4">
                    <a href="#" target="_blank" class="me-3 text-white">
                      <i className="bi bi-facebook"></i>
                    </a>
                    <a href="#" target="_blank" class="me-3 text-white">
                      <i className="bi bi-twitter-x"></i>
                    </a>
                    <a href="#" target="_blank" className="me-3 text-white">
                      <i className="bi bi-instagram"></i>
                    </a>
                    <a href="#" target="_blank" className="text-white">
                      <i className="bi bi-youtube"></i>
                    </a>
                  </div>
                </div>
              </div>

              <div class="mb-4 mt-5 pt-3">
                <h3 class="fw-bold text-white">Total Visitors</h3>
                <p class="mb-1 text-white">
                  45850
                </p>
              </div>
            </div>
            <div class="offset-lg-1 col-lg-2 col-md-3 col-6">
              <div class="mb-4">
                <h3 class="fw-bold mb-3 text-white">Pages</h3>
                <ul class="list-unstyled nav nav-footer flex-column nav-x-0">
                  <li>
                    <Link to="/" class="nav-link text-white">
                      Why Acharyakulam
                    </Link>
                  </li>
                  <li>
                    <Link to="/states" class="nav-link text-white">
                      Mission & Vision
                    </Link>
                  </li>
                  <li>
                    <Link to="/departments" class="nav-link text-white">
                      Faculty
                    </Link>
                  </li>
                  <li>
                    <Link to="/saved" class="nav-link text-white">
                      Virtual Tour
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact" class="nav-link text-white">
                      Mandatory Disclosure
                    </Link>
                  </li>
                  <li>
                    <Link to="/terms-conditions" class="nav-link text-white">
                      Rules & Regulation
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy" class="nav-link text-white">
                      Blogs and Articles
                    </Link>
                  </li>
                  <li>
                    <Link to="/disclaimer" class="nav-link text-white">
                      Prospectus
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy" class="nav-link text-white">
                      Photos
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy" class="nav-link text-white">
                      Vidoes
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy" class="nav-link text-white">
                      Media Print
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-2 col-md-3 col-6">
              <div class="mb-4">
                <h3 class="fw-bold mb-3 text-white">Links</h3>
                <ul class="list-unstyled nav nav-footer flex-column nav-x-0">
                  <li>
                    <Link
                      to="/category"
                      state={{
                        categoryName: "Latest Jobs",
                        categoryId: "5ydb3lom",
                      }}
                      class="nav-link text-white"
                    >
                      Student Council
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/category"
                      state={{
                        categoryName: "Results",
                        categoryId: "jbwm9i43",
                      }}
                      class="nav-link text-white"
                    >
                      Topper Student
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/category"
                      state={{
                        categoryName: "Admit Card",
                        categoryId: "Oj38G4ef",
                      }}
                      class="nav-link text-white"
                    >
                      Academic Session
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/category"
                      state={{
                        categoryName: "Banking",
                        categoryId: "o90g88ug",
                      }}
                      class="nav-link text-white"
                    >
                      Academic Calendar
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/category"
                      state={{
                        categoryName: "Police",
                        categoryId: "t1u9o16c",
                      }}
                      class="nav-link text-white"
                    >
                      Curriculum Information
                    </Link>
                  </li>
                  <li>
                    <Link class="nav-link text-white">
                      Participation In Yoga
                    </Link>
                  </li>
                  <li>
                    <Link class="nav-link text-white">About Facilities</Link>
                  </li>
                  <li>
                    <Link class="nav-link text-white">Online Fee Payment</Link>
                  </li>
                  <li>
                    <Link class="nav-link text-white">Feedback</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-3 col-md-12">
              <div class="mb-4">
                <h3 class="fw-bold mb-3 text-white">Get in touch</h3>
                <p class="mb-1 text-white">
                  Address:
                  <a href="#" className="text-white">
                    {" "}
                    Namkum, Near Police Station, Ranchi, India, Jharkhand
                  </a>
                </p>
                <p class="mb-1 text-white">
                  Email:
                  <a
                    href="mailto:officeacharyakulamranchi@gmail.com"
                    className="text-white"
                  >
                    {" "}
                    Send E-Mail
                  </a>
                </p>
                <p className="text-white">
                  Phone:
                  <span class="fw-semibold">
                    +91-6287842467
                    <br />
                    +91-6287842461
                  </span>
                </p>
              </div>

              <div class="mb-4">
                <h3 class="fw-bold mb-3 text-white">Principal Hours</h3>
                <p class="mb-1 text-white">
                  Mon - Sat :
                  <a href="#" className="text-white">
                    {" "}
                    9:00 am - 1:00 pm
                  </a>
                </p>
                <p class="mb-1 text-white">
                  Sun :
                  <a href="#" className="text-white">
                    {" "}
                    Closed
                  </a>
                </p>
              </div>

              <div class="mb-4">
                <h3 class="fw-bold mb-3 text-white">Office Hours</h3>
                <p class="mb-1 text-white">
                  Mon - Sat :
                  <a href="#" className="text-white">
                    {" "}
                    9:00 am - 2:00 pm
                  </a>
                </p>
                <p class="mb-1 text-white">
                  Sun :
                  <a href="#" className="text-white">
                    {" "}
                    Closed
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div class="row align-items-center g-0 py-2 mt-6">
            <div class="col-lg-4 col-md-5 col-12">
              <span className="text-white">© 2024 Acharyakulam Ranchi</span>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
