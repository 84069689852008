function Principal() {
  return (
    <>
      <section className="other-pages-hero-bg">
        <div className="container py-5">
          <div className="row py-5">
            <div className="col-xxl-6 offset-xxl-3 col-md-8 offset-md-2 col-12">
              <div className="text-center mb-3">
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-column gap-2">
                    <span className="text-dark fs-5">ACHARYAKULAM</span>
                  </div>
                  <h1 className="display-4 mb-0 lh-1">
                    Our Principal
                    <br />
                    <span className="text-primary">Intoduction</span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-8">
        <div className="container">
          <div className="row align-items-center py-lg-5">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="pe-lg-6">
                <span className="text-primary ls-md text-uppercase fw-semibold">
                  OUR PRINCIPAL
                </span>
                <h2 className="display-4 mt-4 mb-3 fw-bold">
                  Meet Our Principal
                </h2>
                <h3>
                  Our Principal is a dedicated leader committed to fostering a
                  positive and inspiring school environment.
                </h3>
                <div className="mt-6">
                  <div className="d-flex mb-4">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        fill="currentColor"
                        className="bi bi-star-fill text-primary mt-1"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                      </svg>
                    </div>
                    <div className="ms-3">
                      <h3 className="mb-2">Divine Mentorship</h3>
                      <p className="mb-0 fs-4">
                        Guided by spiritual leaders, Acharyakulam combines
                        spiritual wisdom with education.
                      </p>
                    </div>
                  </div>
                  <div className="d-flex mb-4">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        fill="currentColor"
                        className="bi bi-life-preserver mt-1 text-primary"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm6.43-5.228a7.025 7.025 0 0 1-3.658 3.658l-1.115-2.788a4.015 4.015 0 0 0 1.985-1.985l2.788 1.115zM5.228 14.43a7.025 7.025 0 0 1-3.658-3.658l2.788-1.115a4.015 4.015 0 0 0 1.985 1.985L5.228 14.43zm9.202-9.202-2.788 1.115a4.015 4.015 0 0 0-1.985-1.985l1.115-2.788a7.025 7.025 0 0 1 3.658 3.658zm-8.087-.87a4.015 4.015 0 0 0-1.985 1.985L1.57 5.228A7.025 7.025 0 0 1 5.228 1.57l1.115 2.788zM8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"></path>
                      </svg>
                    </div>
                    <div className="ms-3">
                      <h3 className="mb-2">Modern Meets Ancient</h3>
                      <p className="mb-0 fs-4">
                        Blending ancient wisdom with modern science for a
                        comprehensive learning experience.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="mt-4 mt-lg-0">
                <img
                  src="/assets/images/principal.jpg"
                  alt="featured"
                  className="w-100 object-fit-cover rounded-5"
                  height="500"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Principal;
