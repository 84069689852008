import { useState } from "react";

function SwamiRamdevJi() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleContent = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <section className="other-pages-hero-bg">
        <div className="container py-5">
          <div className="row py-5">
            <div className="col-xxl-6 offset-xxl-3 col-md-8 offset-md-2 col-12">
              <div className="text-center mb-3">
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-column gap-2">
                    <span className="text-dark fs-5">ACHARYAKULAM</span>
                  </div>
                  <h1 className="display-4 mb-0 lh-1">
                    Yogrishi Swami Ramdev Ji
                    <br />
                    <span className="text-primary">Intoduction</span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-6">
        <div className="container">
          <div className="row align-items-center py-lg-8">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="pe-lg-6">
                <span className="text-primary ls-md text-uppercase fw-semibold">
                  Swami Ji
                </span>
                <h2 className="display-4 mt-4 mb-3 fw-bold">
                  Yogrishi Swami Ramdev Ji
                </h2>
                <h3>
                  Journey of Yogrishi Swami Ramdev Ji: From Haryana to Gurukul
                  Mastery
                </h3>
                <div className="mt-6">
                  <p className="lead">
                    Yogrishi Swami Ramdev Ji, born in Haryana, studied Sanskrit
                    and Yoga at Kalwa Gurukul under Acharya Shri Baldevji,
                    earning a postgraduate degree. Influenced by Maharsi
                    Patanjali and inspired by Maharshi Dayanand, he deeply
                    engaged with Vedic texts and practices.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="mt-4 mt-lg-0">
                <img
                  src="/assets/images/swamiji.webp"
                  alt="featured"
                  className="w-100 object-fit-cover rounded-5"
                  height="500"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-8">
        <div className="container pb-6">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-md-12 col-12">
              <div className="text-center">
                <h1 className="display-4 fw-bold">
                  Swami <span className="colorful-text">Ramdev Ji</span>
                </h1>
                <p className="lead px-8">
                  Yogrishi Swami Ramdev Ji was born to Smt. Gulab Devi and Shri
                  Ram Niwas in a village of Haryana.
                </p>
              </div>
              <p className="lead text-center mt-5">
                Quite early in his life he had his goals cut out for him, so he
                chose the path of celibacy and asceticism. After doing a stint
                of teaching Yoga, Paṇini’s Aṣṭadhyayi and Patanjali’s Mahabhaṣya
                at Gurukuls, he set out on his journey to the Gangotri caves of
                lofty Himalayas, away from the distractions of mundane
                activities.Through deep meditation and ascetic discipline and
                penance he was able to develop a clear vision of the work to be
                done by him:
              </p>
              <p className="lead text-center">
                Propagation of yoga and Ayurveda.
              </p>
              <p className="lead text-center">
                Reforming the social, political and economic system of India.
              </p>
              <p className="lead text-center">
                Luckily he met with Dr. Acharya Balkrishna Ji, a kindred soul
                and a schoolmate, who was out there on a similar quest. They
                came together to launch upon this stupendous task from scratch.
              </p>

              {isOpen && (
                <div>
                  <p className="lead text-center">
                    Swamiji took upon himself the onerous responsibility of
                    demystifying and popularizing Patanjali’s Yoga, while Dr.
                    Acharya ji devoted himself to the task of restoring people’s
                    faith in the efficacy of ayurvedic system of medicine.
                    Swamiji’s main focus is on making the people of India as
                    well as of the whole world adopts yoga and Ayurveda as their
                    lifestyle. His approach to treating ailments and disorders
                    in pragmatic, undogmatic and non-sectarian. All persons
                    whether Hindu, Muslim, Sikh or Christian, have the same
                    anatomy and physiology. Therefore they can all benefit from
                    yoga and auyrvedic therapy. He has explained in detail the
                    benefits accruing from yoga in his two popular hindi books
                    on the subject:
                  </p>
                  <p className="lead text-center">
                    1. Yoga Sadhana evam Yoga Chikitsa Rahasya
                  </p>
                  <p className="lead text-center">2. Pranayama Rahasya.</p>
                  <p className="lead text-center">
                    In his yoga camps, attended by thousands of participants
                    from all parts of the country, he emphasizes on doing eight
                    Pranayamas
                  </p>
                  <p className="lead text-center">
                    1.Bhastrika 2.Kapalabhati 3.Bahya / Agnisar 4.Ujjayi
                    5.Anulomaviloma 6.Bhramari 7.Udgitha 8.Pranava Some
                    uksma(light) vyayamas; and some specific asanas for various
                    ailments, as also some simple home remedies and ayurvedic
                    medicines. Within a short span of time the results of yoga
                    and auyrvedic therapy have not only been encouraging but
                    also astounding. People have taken to yoga in a big way;
                    they are doing it under the guidance of yoga teachers
                    trained and certified by Swamiji’s Patanjali Yogpeeth, and
                    watching and following it on various Indian TV channels,
                    like AASTHA, ZEETV, STAR, SAHARA etc. People are learning
                    yoga from the CDs, DVDs, audio-video cassettes prepared by
                    the yogpeeth, Swamiji has tried to explain the Yogasutras of
                    Patanjali in simple Hindi in his book Yogadarsana.
                  </p>
                  <p className="lead text-center">
                    To give concrete shape to his dreams, Swamiji founded Divya
                    Yog Mandir (Trust) in 1995 at Kankhal, Hardwar, Uttarakhand,
                    India. It was followed by meditation Centre at Gangotri in
                    the Himalayas; Brahmakalpa Chikitsalaya; Divya Pharmacy;
                    Divya Prakashan; Divya Yog Sadhana; Patanjali Yogpeeth
                    (Trust) in Delhi in 2005; Patanjali Yogpeeth, Hardwar;
                    Mahashaya Hiralal Arsh Gurukul, Kishangarh Ghaseda,
                    Mahendragarh, Haryana; Yog Gram and recently Bharat
                    Swabhiman (Trust) in Delhi.
                  </p>
                  <p className="lead text-center">
                    While yoga will take care of physical, mental and spiritual
                    health, the downslide, in social, political and economic
                    system of the country will be salvaged only through the
                    patriotic zeal, fearlessness and strong character, of which
                    he himself is a living example. Recently he has launched
                    Bharat Swabhiman Movement which encompasses all the ground
                    realities of the Indian social, political and economic
                    scene. He has given a clarion call to the people to come
                    forward and save the country and the democracy. He wants to
                    see an addiction-free, vegetarian, corruption-free India,
                    proud of its swadeshi products.
                  </p>
                  <p className="lead text-center">
                    While his mind may be soaring in the ethereal spheres of
                    spiritualism, but his feet are firmly planted on earth where
                    he is very much alive to the mundane concerns such as
                    treatment/ enrichment of soil, cow breeding/cow protection,
                    cleaning the Ganga etc.
                  </p>
                  <p className="lead text-center">
                    For his epoch-making work, various honours/ honorary
                    doctorates by universities have been conferred on him in
                    India and abroad. On the invitation of Secretary-General of
                    U.N.O., Swamiji has led the stand-up programme to pass a
                    resolution against poverty eradication from the whole world,
                    at United Nation Organisation’s head-quarter in New York, on
                    15 October 2006.
                  </p>
                </div>
              )}

              <div className="d-flex justify-content-center">
                {!isOpen && (
                  <button
                    onClick={toggleContent}
                    className="btn-outline-dark-secondary mt-4 px-5 py-2 bg-transparent border-1 border-secondary rounded-4"
                  >
                    <span className="mx-3 fs-4">Read More</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SwamiRamdevJi;
