import { Link } from "react-router-dom";
import { TypeAnimation } from "react-type-animation";

function Header() {
  return (
    <>
      <section>
        <div className="container border-bottom py-2">
          <div className="row">
            <div className="col d-flex align-items-center">
              <div className="d-flex align-items-center gap-4">
                <div className="d-flex gap-2 align-items-center lh-0 d-none d-md-block">
                  <span class="btn btn-warning py-1 rounded-pill fw-normal me-3">
                    <p className="fs-8 m-0">Login</p>
                  </span>
                  <div class="btn bg-primary rounded-circle py-1 px-2 fw-normal">
                    <i className="bi bi-megaphone text-white"></i>
                  </div>
                  <span className="notice-text-animation fw-medium ms-2">
                    <TypeAnimation
                      sequence={[
                        "Results for 2023 - 24",
                        1000,
                        "Documents Required for Admission",
                        1000,
                        "Important Information for Admission",
                        1000,
                      ]}
                      wrapper="span"
                      speed={60}
                      repeat={Infinity}
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="col text-center">
              <span>
                <img
                  src="https://cdn-icons-png.flaticon.com/512/8605/8605170.png"
                  width="25"
                  className="om-animation"
                />
              </span>
            </div>
            <div className="col d-flex justify-content-end">
              <div className="d-flex align-items-center gap-4">
                <div className="d-flex gap-2 align-items-center lh-0 d-none d-md-block">
                  <i className="bi bi-envelope ms-3"></i>
                  <i className="bi bi-facebook ms-3"></i>
                  <i className="bi bi-instagram ms-3"></i>
                  <i className="bi bi-twitter-x ms-3"></i>
                  <i className="bi bi-linkedin ms-3"></i>
                  <span class="btn btn-primary blink-animation py-1 rounded-pill fw-normal ms-5">
                    <p className="fs-8 m-0 text-white">Admission Open</p>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <nav class="navbar navbar-expand-lg my-header-bg sticky-top py-1">
        <div class="container">
          <Link to="/" class="navbar-brand">
            <img src="/assets/images/logo.png" height="55" alt="Logo" />
          </Link>
          <div class="d-flex align-items-center order-lg-3">
            <div class="d-flex align-items-center">
              <div class="d-none d-md-block">
                <div className="d-flex justify-content-center">
                  <div className="d-flex align-items-center">
                    <a
                      href="#"
                      target="_blank"
                      class="btn btn-warning rounded-3 ms-1"
                    >
                      <i className="bi bi-telephone-outbound"></i>
                    </a>
                    <div className="lh-1 ms-2 me-4">
                      <p className="es-text m-0">Need Help ? Call now</p>
                      <p className="fs-7 text-dark m-0 mt-1">6287842467</p>
                    </div>
                  </div>
                  <div>
                    <a
                      href="#"
                      target="_blank"
                      class="btn btn-primary rounded-3 ms-1"
                    >
                      <i className="bi bi-envelope"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button
                class="navbar-toggler collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbar-default"
                aria-controls="navbar-default"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="icon-bar top-bar mt-0"></span>
                <span class="icon-bar middle-bar"></span>
                <span class="icon-bar bottom-bar"></span>
              </button>
            </div>
          </div>

          <div class="collapse navbar-collapse" id="navbar-default">
            <ul class="navbar-nav mx-auto">
              <li class="nav-item dropdown">
                <span
                  class="nav-link dropdown-toggle cursor-pointer"
                  id="navbarPages"
                >
                  About Us
                </span>
                <ul
                  class="dropdown-menu dropdown-menu-arrow"
                  aria-labelledby="navbarPages"
                >
                  <li>
                    <Link to="/why-acharyakulam" class="dropdown-item">
                      Why Acharyakulam
                    </Link>
                  </li>
                  <li>
                    <Link to="/mission-vision" class="dropdown-item">
                      Mission & Vision
                    </Link>
                  </li>
                  <li class="dropdown-submenu dropend">
                    <a
                      class="dropdown-item dropdown-list-group-item dropdown-toggle"
                      href="#"
                    >
                      Torch Bearers
                    </a>
                    <ul class="dropdown-menu">
                      <li>
                        <Link to="/swami-ramdev-ji" class="dropdown-item">
                          Yogrishi Swami Ramdev Ji
                        </Link>
                      </li>
                      <li>
                        <Link to="/acharya-balkrishna-ji" class="dropdown-item">
                          Dr. Acharya Balkrishna Ji
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/principal" class="dropdown-item">
                      Principal's Desk
                    </Link>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      Teachers & Office Staff
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      Virtual Tour
                    </a>
                  </li>
                  <li>
                    <Link to="/career" class="dropdown-item">
                      Career
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact" class="dropdown-item">
                      Contact Us
                    </Link>
                  </li>
                  <li>
                    <hr class="mx-3" />
                  </li>
                  <li>
                    <Link to="/mandatory-disclosure" class="dropdown-item">
                      Mandatory Disclosure
                    </Link>
                  </li>
                </ul>
              </li>
              <li class="nav-item dropdown">
                <span
                  class="nav-link dropdown-toggle cursor-pointer"
                  id="navbarPages"
                >
                  Admission
                </span>
                <ul
                  class="dropdown-menu dropdown-menu-arrow"
                  aria-labelledby="navbarPages"
                >
                  <li>
                    <Link to="/admission-procedure" class="dropdown-item">
                      Admission Procedure
                    </Link>
                  </li>
                  <li class="dropdown-submenu dropend">
                    <a
                      class="dropdown-item dropdown-list-group-item dropdown-toggle"
                      href="#"
                    >
                      Online Admission
                    </a>
                    <ul class="dropdown-menu">
                      <li>
                        <a class="dropdown-item" href="#">
                          Application Forms
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="#">
                          Admission Status
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      Admission Pamphlet 2024-25
                    </a>
                  </li>
                  <li>
                    <Link to="/" class="dropdown-item">
                      Rules & Regulation
                    </Link>
                  </li>
                  <li>
                    <a
                      href="/media/prospectus.pdf"
                      target="_blank"
                      class="dropdown-item"
                    >
                      Prospectus
                    </a>
                  </li>
                </ul>
              </li>
              <li class="nav-item dropdown dropdown-fullwidth">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Academics
                </a>
                <div class="dropdown-menu dropdown-menu-md">
                  <div class="px-4 pt-2 pb-2">
                    <div class="row">
                      <div class="col-lg-4 col-12 text-center">
                        <div class="border-bottom pb-2 mb-3">
                          <h5 class="mb-0">Information</h5>
                        </div>
                        <div>
                          <ul class="nav flex-column">
                            <li class="nav-item">
                              <a href="#" class="nav-link">
                                Topper Students
                              </a>
                            </li>
                            <li class="nav-item">
                              <a href="#" class="nav-link">
                                Download TC
                              </a>
                            </li>
                            <li class="nav-item">
                              <a href="#" class="nav-link">
                                Download Certificates
                              </a>
                            </li>
                            <li class="nav-item">
                              <a href="#" class="nav-link">
                                Participation in Competitive Exam
                              </a>
                            </li>
                            <li class="nav-item">
                              <a href="#" class="nav-link">
                                Participation In Yoga
                              </a>
                            </li>
                            <li class="nav-item">
                              <a href="#" class="nav-link">
                                100% Attendance
                              </a>
                            </li>
                          </ul>
                          <div class="mt-4">
                            <a
                              href="#"
                              class="btn btn-outline-primary btn-sm px-5"
                            >
                              Fee Payment
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-12 mt-4 mt-lg-0 text-center">
                        <div class="border-bottom pb-2 mb-3">
                          <h5 class="mb-0">Students</h5>
                        </div>
                        <div>
                          <ul class="nav flex-column">
                            <li class="nav-item">
                              <a href="#" class="nav-link">
                                Student Council
                              </a>
                            </li>
                            <li class="nav-item">
                              <a href="#" class="nav-link">
                                School Uniform
                              </a>
                            </li>
                            <li class="nav-item">
                              <a href="#" class="nav-link">
                                House System
                              </a>
                            </li>
                            <li class="nav-item">
                              <a href="#" class="nav-link">
                                Today's Birthdays
                              </a>
                            </li>
                            <li class="nav-item">
                              <a href="#" class="nav-link">
                                Dincharya
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-lg-4 col-12 mt-4 mt-lg-0 text-center">
                        <div class="border-bottom pb-2 mb-3">
                          <h5 class="mb-0">Curriculum</h5>
                        </div>
                        <div>
                          <ul class="nav flex-column">
                            <li class="nav-item">
                              <a href="#" class="nav-link">
                                Academic Session
                              </a>
                            </li>
                            <li class="nav-item">
                              <a href="#" class="nav-link">
                                Academic Calendar
                              </a>
                            </li>
                            <li class="nav-item">
                              <a href="#" class="nav-link">
                                Curriculum Information
                              </a>
                            </li>
                          </ul>

                          <div class="mt-4">
                            <a
                              href="#"
                              class="btn btn-outline-primary btn-sm px-5"
                            >
                              View all
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="nav-item dropdown">
                <span
                  class="nav-link dropdown-toggle cursor-pointer"
                  id="navbarPages"
                >
                  Blogs & Gallery
                </span>
                <ul
                  class="dropdown-menu dropdown-menu-arrow"
                  aria-labelledby="navbarPages"
                >
                  <li>
                    <a class="dropdown-item" href="#">
                      Blogs and Articles
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      Photos
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      Vidoes
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      Media Print
                    </a>
                  </li>
                </ul>
              </li>
              <li class="nav-item dropdown dropdown-fullwidth">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Facilities
                </a>
                <div class="dropdown-menu dropdown-menu-md">
                  <div class="px-4 pt-2 pb-2">
                    <div class="row">
                      <div class="col-lg-4 col-12 text-center">
                        <div class="border-bottom pb-2 mb-3">
                          <h5 class="mb-0">Pages</h5>
                        </div>
                        <div>
                          <ul class="nav flex-column">
                            <li class="nav-item">
                              <a href="#" class="nav-link">
                                Online Fee Payment
                              </a>
                            </li>
                            <li class="nav-item">
                              <a href="#" class="nav-link">
                                Library
                              </a>
                            </li>
                            <li class="nav-item">
                              <a href="#" class="nav-link">
                                Emergency Preparedness
                              </a>
                            </li>
                            <li class="nav-item">
                              <a href="#" class="nav-link">
                                Parent Resource Center
                              </a>
                            </li>
                            <li class="nav-item">
                              <a href="#" class="nav-link">
                                Feedback
                              </a>
                            </li>
                            <li class="nav-item">
                              <a href="#" class="nav-link">
                                Suggestions Box
                              </a>
                            </li>
                          </ul>
                          <div class="mt-4">
                            <a
                              href="#"
                              class="btn btn-outline-primary btn-sm px-5"
                            >
                              Fee Payment
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-12 mt-4 mt-lg-0 text-center">
                        <div class="border-bottom pb-2 mb-3">
                          <h5 class="mb-0">Links</h5>
                        </div>
                        <div>
                          <ul class="nav flex-column">
                            <li class="nav-item">
                              <a href="#" class="nav-link">
                                Laboratories
                              </a>
                            </li>
                            <li class="nav-item">
                              <a href="#" class="nav-link">
                                Art Room
                              </a>
                            </li>
                            <li class="nav-item">
                              <a href="#" class="nav-link">
                                Assembly Ground
                              </a>
                            </li>
                            <li class="nav-item">
                              <a href="#" class="nav-link">
                                Music Room
                              </a>
                            </li>
                            <li class="nav-item">
                              <a href="#" class="nav-link">
                                Labs
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-lg-4 col-12 mt-4 mt-lg-0 text-center">
                        <div class="border-bottom pb-2 mb-3">
                          <h5 class="mb-0">Curriculum</h5>
                        </div>
                        <div>
                          <ul class="nav flex-column">
                            <li class="nav-item">
                              <a href="#" class="nav-link">
                                Academic Session
                              </a>
                            </li>
                            <li class="nav-item">
                              <a href="#" class="nav-link">
                                Academic Calendar
                              </a>
                            </li>
                            <li class="nav-item">
                              <a href="#" class="nav-link">
                                Curriculum Information
                              </a>
                            </li>
                          </ul>

                          <div class="mt-4">
                            <a
                              href="#"
                              class="btn btn-outline-primary btn-sm px-5"
                            >
                              View all
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;
