import { useState } from "react";

function AcharyaBalkrishnaJi() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleContent = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <section className="other-pages-hero-bg">
        <div className="container py-5">
          <div className="row py-5">
            <div className="col-xxl-6 offset-xxl-3 col-md-8 offset-md-2 col-12">
              <div className="text-center mb-3">
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-column gap-2">
                    <span className="text-dark fs-5">ACHARYAKULAM</span>
                  </div>
                  <h1 className="display-4 mb-0 lh-1">
                    Dr. Acharya Balkrishna Ji
                    <br />
                    <span className="text-primary">Intoduction</span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-6">
        <div className="container">
          <div className="row align-items-center py-lg-8">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="pe-lg-6">
                <span className="text-primary ls-md text-uppercase fw-semibold">
                  Balkrishna Ji
                </span>
                <h2 className="display-4 mt-4 mb-3 fw-bold">
                  Dr. Acharya Balkrishna Ji
                </h2>
                <h3>
                  Journey of Yogrishi Swami Ramdev Ji: From Haryana to Gurukul
                  Mastery
                </h3>
                <div className="mt-6">
                  <p className="lead">
                    Dr. Acharya Balkrishna, a great scholar of Ayurveda,
                    Sanskrit language and grammar, and the Vedas, was born to
                    Smt. Sumitra Devi and Shri Jay Vallabh.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="mt-4 mt-lg-0">
                <img
                  src="/assets/images/acharyaji.jpg"
                  alt="featured"
                  className="w-100 object-fit-cover rounded-5"
                  height="500"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-8">
        <div className="container pb-6">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-md-12 col-12">
              <div className="text-center">
                <h1 className="display-4 fw-bold">
                  Dr. Acharya{" "}
                  <span className="colorful-text">Balkrishna Ji</span>
                </h1>
                <p className="lead px-8">
                  Discoveries and Contributions in Ayurveda by Dr. Acharyaji
                  Balkrishna Ji.
                </p>
              </div>
              <p className="lead text-center mt-5">
                During his journey and austere penance in the Himalayas, he was
                able to explore four rare and extinct astavarga plants used as
                ingredients in the preparation of cyavanaprasa, an ayurvedic
                tonic. He is also credited with discovering the sanjivani buti,
                the medicinal plant of legendary fame. As the head of all the
                medical institutions and chikitsalayas (Hospitals and clinics)
                functioning under the aegis of Patanjali Yogpeeth, he is mainly
                focused on the research and development of Ayurveda to make it
                complete successfully with the modern medical science.{" "}
              </p>

              <p className="lead text-center mt-5">
                He has been able to cure lakhs of patients at his Brahmakalpa
                Chikitsalaya of several stubborn, chronic and incurable diseases
                like diabetes, rheumatism, osteoarthritis, gout, migraine,
                cervical spondylitis, respiratory disorders, asthma, cancer,
                nervous disorder, heart disease, brain diseases, etc. Yoga and
                Ayurveda have combined wonderfully in the treatment of these
                diseases at a very nominal price. These successful treatments
                have been documented by Dr. Acharyaji in his renowned book: Yoga
                In Synergy With Medical Science. Another popular book written by
                him is Aushadh Darshan, a handy pharmacopoeia of ayurvedic and
                home remedies.
              </p>
              <p className="lead text-center">
                At the Department of Medical Science In Yoga & Ayurveda at
                Patanjali Yogpeeth, Hardwar, he has got a team of 70 physicians
                to assist him. Besides, more than a thousand vaidyas (ayurvedic
                physicians) in India and abroad are treating patients under his
                guidance.
              </p>
              <p className="lead text-center">
                Reforming the social, political and economic system of India.
              </p>
              <p className="lead text-center">
                To ensure the efficiency of ayurvedic treatments, it was
                necessary to make pure and high-quality medicines available to
                patients at an affordable price. Dr. Acharyaji took up the
                challenge and found Divya Pharmacy where Ayurvedic medicines
                with national and international certifications are manufactured
                with upmarket packaging. To ensure the use of genuine
                ingredients in the Divya Pharmacy medicines, Patanjali Herbal
                Park grows 450 medicinal plants (some of them rare) under the
                overall supervision of Dr. Acharya Ji.
              </p>

              {isOpen && (
                <div>
                  <p className="lead text-center">
                    As a part of Swamiji's Bharat Swabhiman Movement, items of
                    daily use like tooth powder, toothpaste, hair oil, soap,
                    shampoo, beauty creams etc. are produced in Divya Pharmacy &
                    Patanjali Ayurved Ltd. with Ayurvedic ingredients and
                    Ayurvedic formulas.
                  </p>
                  <p className="lead text-center">
                    Dr. Acharya Ji's knowledge and experience in the managerial,
                    administrative and engineering fields, which has been
                    admired by one and all in India is another aspect of his
                    multi-faceted genius and personality. The impressive
                    infrastructure of Patanjali Yogpeeth buildings is a living
                    testimony to his grand futuristic concepts.
                  </p>
                  <p className="lead text-center">
                    His editorial skills can be seen in Yog Sandesh where, as
                    the Chief Editor, he propagates yoga and ayurveda for the
                    mental, physical and spiritual health of people. All his
                    activities are inspired by this dictum of Bhagavad Geeta:
                    “kamaye duhkhataptanam praninamartinasanam”.
                  </p>
                  <p className="lead text-center">
                    He has also edited, collaborated and authored books like
                    Ayurved: Its Principles & Philosophies, Secrets Of Indian
                    Herbs, Vitality Strengthening Astavarga Plants, Vaidika
                    Nityakarma Vidhi, Yogadars'ana, Sant Darshan and Bhakti
                    Gitanjali.
                  </p>
                  <p className="lead text-center">
                    By devoting himself to the service of humanity in many ways,
                    round the clock, he has become a true karmayogi.
                  </p>
                </div>
              )}

              <div className="d-flex justify-content-center">
                {!isOpen && (
                  <button
                    onClick={toggleContent}
                    className="btn-outline-dark-secondary mt-4 px-5 py-2 bg-transparent border-1 border-secondary rounded-4"
                  >
                    <span className="mx-3 fs-4">Read More</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AcharyaBalkrishnaJi;
