function MandatoryDisclosure() {
  return (
    <>
      <section className="other-pages-hero-bg">
        <div className="container py-5">
          <div className="row py-5">
            <div className="col-xxl-6 offset-xxl-3 col-md-8 offset-md-2 col-12">
              <div className="text-center mb-3">
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-column gap-2">
                    <span className="text-dark fs-5">ACHARYAKULAM</span>
                  </div>
                  <h1 className="display-4 mb-0 lh-1">
                    Mandatory Disclosure
                    <br />
                    <span className="text-primary">Introduction</span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="py-8 mt-5">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-8 col-md-12 col-12">
              <div class="text-center">
                <img src="/assets/images/icon.png" width="120" />
                <h1 class="display-5 fw-bold mt-4">
                  ACHARYAKULAM
                  <br />
                  TATA ROAD NAMKUM RANCHI
                </h1>
                <p class="lead px-8">
                  Integrating Ancient Gurukul Wisdom with Modern Educational
                  Excellence
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="pb-8 mb-4">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-8 col-md-12 col-12">
              <div class="py-3 mb-5 border-bottom">
                <h3>BASIC DETAILS</h3>
              </div>
              <div class="mt-5">
                <p class="fs-4 d-flex justify-content-between">
                  SCHOOL NAME :
                  <span className="text-uppercase text-dark">ACHARYAKULAM</span>
                </p>
                <p class="fs-4 d-flex justify-content-between">
                  ADDRESS :
                  <span className="text-uppercase text-dark">
                    TATA ROAD NEAR NAMKUM POLICE STATION RANCHI-JHARKHAND
                  </span>
                </p>
                <p class="fs-4 d-flex justify-content-between">
                  PRINCIPAL :
                  <span className="text-uppercase text-dark">
                    MRS SUJATA KAURA
                  </span>
                </p>
                <p class="fs-4 d-flex justify-content-between">
                  PRINCIPAL'S EMAIL-ID :
                  <span className="text-uppercase text-dark">
                    principalacharyakulam2019@gmail.com
                  </span>
                </p>
                <p class="fs-4 d-flex justify-content-between">
                  SCHOOL CONTACT NO :
                  <span className="text-uppercase text-dark">
                    6287842460, 6287842467, 6287842459
                  </span>
                </p>
                <p class="fs-4 d-flex justify-content-between">
                  SCHOOL WEBSITE :
                  <span className="text-uppercase text-dark">
                    WWW.ACHARYAKULAMRANCHI.COM
                  </span>
                </p>
                <p class="fs-4 d-flex justify-content-between">
                  LANDMARK NEAR SCHOOL :
                  <span className="text-uppercase text-dark">
                    NEAR NAMKUM POLICE STATION, TATA ROAD
                  </span>
                </p>
                <p class="fs-4 d-flex justify-content-between">
                  NAME OF THE TRUST/SOCIETY/COMPANY REGISTERS WITH :
                  <span className="text-uppercase text-dark">
                    Patanjali Yogpeeth Trust
                  </span>
                </p>
                <p class="fs-4 d-flex justify-content-between">
                  WHETHER SCHOOL IS RUNNING :
                  <span className="text-uppercase text-dark">Morning</span>
                </p>
                <p class="fs-4 d-flex justify-content-between">
                  SCHOOL TIMING :
                  <span className="text-uppercase text-dark">
                    7:15 AM to 2:15 PM
                  </span>
                </p>
                <p class="fs-4 d-flex justify-content-between">
                  CURRENTLY RUNNING CLASSES FROM :
                  <span className="text-uppercase text-dark">Nursery</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default MandatoryDisclosure;
