import { Link } from "react-router-dom";

function Home() {
  return (
    <>
      <main>
        <div
          id="carouselExampleIndicators"
          className="carousel slide carousel-fade"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src="/assets/images/slider/2.jpg"
                className="d-block w-100 object-fit-cover"
                height="530"
                alt="..."
              />
            </div>
            <div className="carousel-item">
              <img
                src="/assets/images/slider/3.jpg"
                className="d-block w-100 object-fit-cover"
                height="530"
                alt="..."
              />
            </div>
            <div className="carousel-item">
              <img
                src="/assets/images/slider/4.jpeg"
                className="d-block w-100 object-fit-cover"
                height="530"
                alt="..."
              />
            </div>
            <div className="carousel-item">
              <img
                src="/assets/images/slider/5.png"
                className="d-block w-100 object-fit-cover"
                height="530"
                alt="..."
              />
            </div>
            <div className="carousel-item">
              <img
                src="/assets/images/slider/6.png"
                className="d-block w-100 object-fit-cover"
                height="530"
                alt="..."
              />
            </div>
            <div className="carousel-item">
              <img
                src="/assets/images/slider/7.png"
                className="d-block w-100 object-fit-cover"
                height="530"
                alt="..."
              />
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>

        <section>
          <div className="container z-5 position-relative">
            <div className="home-card-top border-0 mt-n6 border primary-gradient">
              <div className="row justify-content-around py-4 gy-4 gy-lg-0">
                <div className="col-3 text-center px-4 border-end-light py-1">
                  <Link className="text-home-card-top m-0">Why Choose Us?</Link>
                </div>
                <div className="col-3 text-center px-4 border-end-light py-1">
                  <Link className="text-home-card-top m-0">
                    Life @ Acharyakulam
                  </Link>
                </div>
                <div className="col-3 text-center px-4 border-end-light py-1">
                  <Link className="text-home-card-top m-0">Fee Payment</Link>
                </div>
                <div className="col-3 text-center px-4 py-1">
                  <Link className="text-home-card-top m-0">Notice Board</Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div
          className="modal fade"
          id="messageAcharyajiModal"
          tabIndex={-1}
          aria-labelledby="messageAcharyajiModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg shadow modal-dialog-centered">
            <div className="modal-content border-0 rounded-5">
              <div className="position-relative d-flex justify-content-end">
                <div
                  type="button"
                  className="modal-close-button bg-white rounded-pill position-absolute m-3"
                  data-bs-dismiss="modal"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    width="20"
                  >
                    <path d="M12.12,10l4.07-4.06a1.5,1.5,0,1,0-2.11-2.12L10,7.88,5.94,3.81A1.5,1.5,0,1,0,3.82,5.93L7.88,10,3.81,14.06a1.5,1.5,0,0,0,0,2.12,1.51,1.51,0,0,0,2.13,0L10,12.12l4.06,4.07a1.45,1.45,0,0,0,1.06.44,1.5,1.5,0,0,0,1.06-2.56Z"></path>
                  </svg>
                </div>
              </div>
              <img
                src="/assets/images/acharyaji.jpg"
                alt="acharyaji"
                className="rounded-top-5 w-100 object-fit-cover"
                height="500"
              />

              <div className="d-flex justify-content-center">
                <div className="col-lg-9 col-10">
                  <div className="p-5 my-5">
                    <h2 className="fs-3 mb-0 h1 lh-1 mt-4">
                      Message from
                      <br />
                      <span className="colorful-text fs-2">
                        Dr. Acharya Balkrishna Ji
                      </span>
                    </h2>
                    <p className="my-4 fs-4">
                      Children are the future of any nation. They are at the
                      centre of the dreams and aspirations of the parents.
                      Parents wish to see their children achieve great heights.
                      They aspire to give their children the best of education
                      and the best of values. The ancient Vaidic Education
                      system of India was the best divine combination of
                      instruction and values. 'Acharyakulam' is the confluence
                      of former Vaidic learning and the best of present-day
                      education. We wish to groom up leaders with high moral
                      values along with the best of current knowledge through
                      Acharyakulam.
                      <br />
                      <br />
                      In earlier times, the leaders of our nation used to be
                      nurtured in the Gurukuls. The pupils used to study the
                      Veda-Vedangas along with the art of defence, economics &
                      virtues. They thereby used to gain the ability of critical
                      thinking of right and wrong.
                    </p>

                    <div className="fs-4 mt-5">
                      <a
                        href="https://www.facebook.com/AcharyBalkrishna/"
                        target="_blank"
                        className="me-3"
                      >
                        <i className="bi bi-facebook fs-3"></i>
                      </a>
                      <a
                        href="https://twitter.com/i/flow/login?redirect_after_login=%2FAch_Balkrishna"
                        target="_blank"
                        className="me-3"
                      >
                        <i className="bi bi-twitter-x fs-3"></i>
                      </a>
                      <a
                        href="https://www.youtube.com/user/acharyabalkrisha"
                        target="_blank"
                        className="me-3"
                      >
                        <i className="bi bi-youtube fs-3"></i>
                      </a>
                      <a
                        href="https://www.linkedin.com/in/acharyabalkrishna/?original_referer=https%3A%2F%2Facharyabalkrishna.com%2F/"
                        target="_blank"
                      >
                        <i className="bi bi-linkedin fs-3"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="messageSwamiRamdevjiModal"
          tabIndex={-1}
          aria-labelledby="messageSwamiRamdevjiModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg shadow modal-dialog-centered">
            <div className="modal-content border-0 rounded-5">
              <div className="position-relative d-flex justify-content-end">
                <div
                  type="button"
                  className="modal-close-button bg-white rounded-pill position-absolute m-3"
                  data-bs-dismiss="modal"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    width="20"
                  >
                    <path d="M12.12,10l4.07-4.06a1.5,1.5,0,1,0-2.11-2.12L10,7.88,5.94,3.81A1.5,1.5,0,1,0,3.82,5.93L7.88,10,3.81,14.06a1.5,1.5,0,0,0,0,2.12,1.51,1.51,0,0,0,2.13,0L10,12.12l4.06,4.07a1.45,1.45,0,0,0,1.06.44,1.5,1.5,0,0,0,1.06-2.56Z"></path>
                  </svg>
                </div>
              </div>
              <img
                src="/assets/images/swamiji.webp"
                alt="acharyaji"
                className="rounded-top-5 w-100 object-position-top object-fit-cover"
                height="500"
              />

              <div className="d-flex justify-content-center">
                <div className="col-lg-9 col-10">
                  <div className="p-5 my-5">
                    <h2 className="fs-3 mb-0 h1 lh-1 mt-4">
                      Message from
                      <br />
                      <span className="colorful-text fs-2">
                        Swami Ramdev Ji
                      </span>
                    </h2>
                    <p className="my-4 fs-4">
                      India has been one of the leading countries in the world
                      in knowledge, wisdom, literature, music, art and culture
                      from the very beginning. Philosophers and travellers from
                      every corner of the globe visited India from time to time
                      to gain knowledge of its religion, philosophy, literature,
                      medicine, astronomy, astrology, and mathematics from the
                      sacred books. They accepted India as a Spiritual Guru and
                      a Torchbearer.
                      <br />
                      <br />
                      Till the 18th century, India had been at the pinnacle in
                      educational, economic, social and spiritual fields. Our
                      contribution to the world market was 37% during the 18th
                      century, and our income was 27% of the total world income.
                      The literacy rate in the country was 97%. According to the
                      famous German scholar Max Muller, English scholar G.W.
                      Leitner and the then British Government,
                    </p>

                    <div className="fs-4 mt-5">
                      <a
                        href="https://www.facebook.com/swami.ramdev/?locale=hi_IN"
                        target="_blank"
                        className="me-3"
                      >
                        <i className="bi bi-facebook fs-3"></i>
                      </a>
                      <a
                        href="https://twitter.com/yogrishiramdev?lang=en"
                        target="_blank"
                        className="me-3"
                      >
                        <i className="bi bi-twitter-x fs-3"></i>
                      </a>
                      <a
                        href="https://www.youtube.com/channel/UCX7SHIccUCj3S4cID-pM-Xg"
                        target="_blank"
                        className="me-3"
                      >
                        <i className="bi bi-youtube fs-3"></i>
                      </a>
                      <a
                        href="https://www.facebook.com/swami.ramdev/?locale=hi_IN"
                        target="_blank"
                      >
                        <i className="bi bi-linkedin fs-3"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="messageSwamijiModal"
          tabIndex={-1}
          aria-labelledby="messageSwamijiModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg shadow modal-dialog-centered">
            <div className="modal-content border-0 rounded-5">
              <div className="position-relative d-flex justify-content-end">
                <div
                  type="button"
                  className="modal-close-button bg-white rounded-pill position-absolute m-3"
                  data-bs-dismiss="modal"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    width="20"
                  >
                    <path d="M12.12,10l4.07-4.06a1.5,1.5,0,1,0-2.11-2.12L10,7.88,5.94,3.81A1.5,1.5,0,1,0,3.82,5.93L7.88,10,3.81,14.06a1.5,1.5,0,0,0,0,2.12,1.51,1.51,0,0,0,2.13,0L10,12.12l4.06,4.07a1.45,1.45,0,0,0,1.06.44,1.5,1.5,0,0,0,1.06-2.56Z"></path>
                  </svg>
                </div>
              </div>
              <img
                src="/assets/images/swamiji.jpg"
                alt="acharyaji"
                className="rounded-top-5 w-100 object-position-top object-fit-cover"
                height="500"
              />

              <div className="d-flex justify-content-center">
                <div className="col-lg-9 col-10">
                  <div className="p-5 my-5">
                    <h2 className="fs-3 mb-0 h1 lh-1 mt-4">
                      Message from
                      <br />
                      <span className="colorful-text fs-2">Swami Ji</span>
                    </h2>
                    <p className="my-4 fs-4">
                      परम पूज्य गुरुदेव और श्रद्धेय आचार्य श्री जी की संकल्पना,
                      दूरदृष्टि, तप, पुरुषार्थ का परिणाम और आधुनिक समय की
                      आवश्यकता है 'आचार्यकुलम्' । आचार्यकुलम् विद्यालय आधुनिक और
                      वैदिक शिक्षा का केन्द्र होने के साथ ही समाज के लिए
                      प्रज्ञावान, संस्कारवान और चरित्रवान मानव निर्माण करने वाला
                      संस्थान है । आचार्यकुलम् विद्यालय निरंतर ऋषि-मुनियों और
                      वेदों की परंपरा को आगे बढ़ाने वाला प्रतिष्ठान है। विद्यालय
                      परिसर का सौम्य, सात्विक, दिव्य वातावरण और विभिन्न प्रकार
                      के फलदार पेड़-पौधे, रंग-बिरंगे पुष्प तथा पशु-पक्षी बच्चों
                      को प्रकृति के निकट लाते है तथा विद्यालय परिसर को और भी
                      अधिक भव्यता और दिव्यता प्रदान करते है। विद्यालय परिसर में
                      विद्यार्थियों के लिए आवश्यक सभी शिक्षण सामग्री, खेल उपकरण,
                      स्मार्ट बोर्ड, कम्प्यूटर लैब, लाइब्रेरी, विज्ञान तथा
                      भौतिकी लैब के साथ ही योग्य तथा अनुभवी अध्यापक गणों की
                      उपस्थिति है। विद्यालय परिवार में विद्यार्थी, अध्यापक,
                      अभिभावक तथा सेवा सहयोगी सभी लोग मिलकर हमारे मूल उद्देश्य
                      "देश के लिए श्रेष्ठ नेतृत्व तैयार करना" को पूरा करने के
                      लिए अहर्निष संकल्पित है। विद्यालय की सभी गतिविधियों को
                      संक्षिप्त रुप में इस पत्रिका के माध्यम से दर्शाया जा रहा
                      है। इस पत्रिका में सभी बच्चों और अध्यापकों के मन की
                      अभिव्यक्ति है । जब हम इस पत्रिका को अध्ययन करेंगे तब इसमें
                      बच्चों तथा अध्यापकों की मनःस्थिति उनकी उपलब्धि और संस्था
                      की प्रगति का दिग्दर्शन करेंगे ।
                      <br />
                      <br />
                      'अभिव्यक्ति' पत्रिका का उद्देश्य विद्यालय की गतिविधियों से
                      परिचय कराना तथा संस्था की उपलब्धि, विद्यार्थियों की
                      उपलब्धि और उनकी चेतना का स्तर, चारित्रिक निर्माण, उनके
                      संस्कार और शैक्षणिक कार्यक्रमों के बारे में जानकारी प्रदान
                      करना है। शिक्षा समाज में एक महत्वपूर्ण भूमिका निभाती है,
                      जो हमारी बुद्धि में ज्ञान का संचार करती है, हमारी सोच तथा
                      व्यवहार को प्रभावित करती है। यह हमें नई विचारधारा,
                      नैतिकता, आदर्शों और समृद्धि की ओर ले जाती है । संस्कार
                      हमारे सामाजिक और व्यक्तिगत जीवन में नैतिक मूल्यों और
                      आदर्शों का प्रतिनिधित्व करते हैं जो हमें शुभ आचरण तथा उच्च
                      आदर्शों की प्राप्ति की ओर अग्रसर करते हैं । अभ्युदय एक
                      सकारात्मक विकास की प्रक्रिया है जो समाज में ज्ञान, विद्या
                      और कौशल के माध्यम से होती है। इसके द्वारा व्यक्ति की
                      समृद्धि और समाज का विकास होता है । निश्रेयसः एक उच्च चेतना
                      की स्थिति है जो शिव संकल्प, सत्कर्मों और सच्चे प्रयासों के
                      माध्यम से प्राप्त होती है। यह हमें समाज में सकारात्मकता
                      तथा आध्यात्मिक उन्नति की दिशा में ले जाता है । जो अंत में
                      आध्यात्मिक और भागवत प्रेरणा की प्राप्ति हेतु मदद करता है।
                      हम शिक्षा के माध्यम से एक स्वस्थ, समृद्ध और संस्कारवान्
                      समाज की स्थापना करने में सफल हो ऐसी परमपिता परमात्मा से
                      प्रार्थना है ।
                      <br />
                      <br />
                      आचार्यकुलम् राँची भारत के नेतृत्व में सकारात्मक परिवर्तन
                      लाने के लिए उच्च गुणवत्ता की शिक्षा प्रदान कर रहा है।
                      हमारा प्रयास है कि यहाँ के विद्यार्थी न केवल विज्ञान और
                      तकनीक के क्षेत्र में उत्कृष्ट हों, बल्कि उन्हें धार्मिकता,
                      नैतिकता और सामाजिक संवेदनशीलता के महत्व को भी समझाया जाए ।
                      आशा है कि हमारे इस अभियान में आप सभी शिक्षकों,
                      विद्यार्थियों एवं अभिभावकगणों का समर्थन एवम् सहयोग निरंतर
                      प्राप्त होगा जिससे कि हम अपने समाज और राष्ट्र में
                      सकारात्मक प्रभाव डाल सकें ।
                      <br />
                      <br />
                      <span className="text-danger">
                        आप सभी शिक्षकों, विद्यार्थियों एवं अभिभावकगणों के लिए
                        मेरी ओर से अनंत शुभकामनाएं और ईश्वर से प्रार्थना है कि
                        आप सभी के जीवन में निरंतर सुख, समृद्धि और स्वास्थ्य
                        बढ़ता रहे। स्वामी दिव्य देव
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="py-4">
          <div className="container">
            <div className="row align-items-center py-lg-8">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="pe-lg-6">
                  <span className="text-primary ls-md text-uppercase fw-semibold">
                    Why choose us
                  </span>
                  <h2 className="display-5 mt-4 mb-3 fw-bold">
                    Why
                    <u className="text-warning">
                      <span className="text-primary"> Acharyakulam</span>
                    </u>
                    ?
                  </h2>
                  <h3>
                    Acharyakulam merges spiritual guidance with cutting-edge
                    education.
                  </h3>
                  <div className="mt-6">
                    <div className="d-flex mb-4">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          fill="currentColor"
                          className="bi bi-star-fill text-primary mt-1"
                          viewBox="0 0 16 16"
                        >
                          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                        </svg>
                      </div>
                      <div className="ms-3">
                        <h3 className="mb-2">Divine Mentorship</h3>
                        <p className="mb-0 fs-4">
                          Guided by spiritual leaders, Acharyakulam combines
                          spiritual wisdom with education.
                        </p>
                      </div>
                    </div>
                    <div className="d-flex mb-4">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          fill="currentColor"
                          className="bi bi-life-preserver mt-1 text-primary"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm6.43-5.228a7.025 7.025 0 0 1-3.658 3.658l-1.115-2.788a4.015 4.015 0 0 0 1.985-1.985l2.788 1.115zM5.228 14.43a7.025 7.025 0 0 1-3.658-3.658l2.788-1.115a4.015 4.015 0 0 0 1.985 1.985L5.228 14.43zm9.202-9.202-2.788 1.115a4.015 4.015 0 0 0-1.985-1.985l1.115-2.788a7.025 7.025 0 0 1 3.658 3.658zm-8.087-.87a4.015 4.015 0 0 0-1.985 1.985L1.57 5.228A7.025 7.025 0 0 1 5.228 1.57l1.115 2.788zM8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"></path>
                        </svg>
                      </div>
                      <div className="ms-3">
                        <h3 className="mb-2">Modern Meets Ancient</h3>
                        <p className="mb-0 fs-4">
                          Blending ancient wisdom with modern science for a
                          comprehensive learning experience.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="mt-4 mt-lg-0 position-relative">
                  <span className="position-absolute top-50 start-100 translate-middle mt-n8 d-xl-block d-none">
                    <a
                      href="/media/prospectus.pdf"
                      target="_blank"
                      className="rounded-pill fs-5 py-1 px-4 bg-primary text-white"
                    >
                      Prospectus
                    </a>
                  </span>
                  <span className="position-absolute top-50 start-0 translate-middle mt-8 d-xl-block d-none">
                    <span className="rounded-pill fs-5 py-1 px-4 bg-primary text-white">
                      Admission
                    </span>
                  </span>
                  <img
                    src="/assets/images/school.jpg"
                    alt="featured"
                    className="w-100 object-fit-cover rounded-5"
                    height="500"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="position-relative z-1">
          <div className="container">
            <div className="primary-gradient rounded-5 py-lg-6 py-8 d-flex justify-content-center text-center">
              <div className="col-md-9 col-12 mt-5">
                <span className="text-white ls-md text-uppercase fw-semibold">
                  Our Goal
                </span>
                <h2 className="display-4 text-white mt-3">
                  Building a Foundation of Values
                </h2>
                <p className="lead text-white opacity-75 px-lg-8 mb-6">
                  The importance of being a parent, we want to build up such a
                  foundation of sanskaras in the children of Acharyakulam
                </p>
                <div className="d-grid d-md-block mb-5">
                  <Link
                    to="/mission-vision"
                    className="btn btn-primary px-5 btn-md"
                  >
                    Read More
                  </Link>
                  <Link
                    to="/why-acharyakulam"
                    className="btn btn-light px-5 btn-md ms-2"
                  >
                    Why Acharyakulam
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="mt-n6">
          <div className="container">
            <div className="bg-dark rounded-bottom-5 px-lg-8 px-5 py-lg-4 py-8 mb-5">
              <div className="d-flex justify-content-between align-items-center mt-5">
                <h4 className="lead text-white fw-normal mt-3">
                  Give your child a Quality Education where Innovation Begins
                </h4>
                <Link
                  to="/mission-vision"
                  className="btn btn-primary px-5 btn-md rounded-pill"
                >
                  Admission
                </Link>
              </div>
            </div>
          </div>
        </div>

        <section className="pb-4">
          <div className="container py-xl-4">
            <div className="row pb-5 mb-3">
              <div className="mt-4 text-center">
                <span className="text-primary ls-md text-uppercase fw-semibold">
                  Our Gurus
                </span>
                <h2 className="display-4 mt-1 mb-0">
                  Our <span className="colorful-text">guiding</span> lights
                </h2>
                <p className="lead mb-0 px-lg-8">
                  Our Gurus, our guiding lights, leading us with wisdom and
                  care.
                </p>
              </div>
            </div>
            <div className="row align-items-center g-3">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="position-relative d-flex align-items-end justify-content-end">
                  <img
                    src="/assets/images/swamiji.webp"
                    alt="featured"
                    className="w-100 object-fit-cover rounded-5"
                    height="450"
                  />
                  <div
                    type="button"
                    className="modal-close-button bg-white rounded-pill position-absolute m-3"
                    data-bs-toggle="modal"
                    data-bs-target="#messageSwamiRamdevjiModal"
                  >
                    <svg
                      className="svg svg-plus svg-plus-cutout"
                      viewBox="0 0 99.598 99.547"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M25.94 49.825C25.94 47.277 27.743 45.515 30.29 45.515H45.577V30.23C45.577 27.69 47.236 25.929 49.711 25.929 52.26 25.93 54.021 27.68 54.021 30.23V45.515H69.36C71.856 45.515 73.66 47.277 73.66 49.825 73.659 52.301 71.845 54.011 69.359 54.011H54.021V69.297C54.021 71.845 52.26 73.597 49.711 73.597 47.236 73.596 45.577 71.782 45.577 69.296V54.01H30.291C27.753 54.01 25.94 52.3 25.94 49.825Z"
                        fill="#000"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="position-relative d-flex align-items-end justify-content-end">
                  <img
                    src="/assets/images/acharyaji.jpg"
                    alt="featured"
                    className="w-100 object-fit-cover rounded-5"
                    height="450"
                  />
                  <div
                    type="button"
                    className="modal-close-button bg-white rounded-pill position-absolute m-3"
                    data-bs-toggle="modal"
                    data-bs-target="#messageAcharyajiModal"
                  >
                    <svg
                      className="svg svg-plus svg-plus-cutout"
                      viewBox="0 0 99.598 99.547"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M25.94 49.825C25.94 47.277 27.743 45.515 30.29 45.515H45.577V30.23C45.577 27.69 47.236 25.929 49.711 25.929 52.26 25.93 54.021 27.68 54.021 30.23V45.515H69.36C71.856 45.515 73.66 47.277 73.66 49.825 73.659 52.301 71.845 54.011 69.359 54.011H54.021V69.297C54.021 71.845 52.26 73.597 49.711 73.597 47.236 73.596 45.577 71.782 45.577 69.296V54.01H30.291C27.753 54.01 25.94 52.3 25.94 49.825Z"
                        fill="#000"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="position-relative d-flex align-items-end justify-content-end">
                  <img
                    src="/assets/images/swamiji.jpg"
                    alt="featured"
                    className="w-100 object-fit-cover rounded-5"
                    height="450"
                  />
                  <div
                    type="button"
                    className="modal-close-button bg-white rounded-pill position-absolute m-3"
                    data-bs-toggle="modal"
                    data-bs-target="#messageSwamijiModal"
                  >
                    <svg
                      className="svg svg-plus svg-plus-cutout"
                      viewBox="0 0 99.598 99.547"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M25.94 49.825C25.94 47.277 27.743 45.515 30.29 45.515H45.577V30.23C45.577 27.69 47.236 25.929 49.711 25.929 52.26 25.93 54.021 27.68 54.021 30.23V45.515H69.36C71.856 45.515 73.66 47.277 73.66 49.825 73.659 52.301 71.845 54.011 69.359 54.011H54.021V69.297C54.021 71.845 52.26 73.597 49.711 73.597 47.236 73.596 45.577 71.782 45.577 69.296V54.01H30.291C27.753 54.01 25.94 52.3 25.94 49.825Z"
                        fill="#000"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="pt-lg-2 pb-lg-7 py-10">
            <div className="container mb-2">
              <div className="row justify-content-center text-center">
                <div className="col-md-9 col-12 mt-5">
                  <span className="text-primary ls-md text-uppercase fw-semibold">
                    Testimonial
                  </span>
                  <h2 className="display-4 mt-1">
                    Voices of Our{" "}
                    <span className="colorful-text">Students</span>
                  </h2>
                  <p className="lead px-lg-8 mb-6">
                    Hear directly from our students as they share their personal
                    experiences and insights.
                  </p>
                </div>
              </div>
            </div>
            <div className="mb-5 overflow-hidden">
              <div className="row g-5">
                <div className="col-lg-6 col-12">
                  <img
                    src="./assets/images/photos/3.jpg"
                    className="w-100 rounded-end-5 object-fit-cover"
                    height="500"
                  />
                </div>
                <div className="col-lg-5 col-12 d-flex align-items-center">
                  <div
                    id="carouselTestimonialAutoplaying"
                    className="carousel slide"
                    data-bs-ride="carousel"
                  >
                    <div className="carousel-indicators ms-5 d-flex justify-content-start">
                      <button
                        type="button"
                        data-bs-target="#carouselTestimonialAutoplaying"
                        data-bs-slide-to="0"
                        className="active"
                        aria-current="true"
                      ></button>
                      <button
                        type="button"
                        data-bs-target="#carouselTestimonialAutoplaying"
                        data-bs-slide-to="1"
                      ></button>
                    </div>
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <div className="p-lg-5 me-lg-5 d-flex mb-5 flex-column justify-content-center h-100">
                          <i className="bi bi-quote text-primary display-1"></i>
                          <p className="display-6 mb-4">
                            Supportive teachers and engaging lessons have helped
                            me discover my passions and build confidence.
                          </p>
                          <span className="text-primary text-uppercase fw-semibold">
                            KAJAL KUMARI
                          </span>
                          <span>8th Grade</span>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className="p-lg-5 me-lg-5 d-flex mb-5 flex-column justify-content-center h-100">
                          <i className="bi bi-quote text-primary display-1"></i>
                          <p className="display-6 mb-4">
                            Acharyakulam is a fantastic place for learning and
                            personal growth, with supportive teachers and a
                            positive environment.
                          </p>
                          <span className="text-primary text-uppercase fw-semibold">
                            PAWAN RAJ
                          </span>
                          <span>9th Grade</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-1">
                  <div className="d-lg-block d-none bg-primary rounded-start-5 w-100 h-100 ms-4"></div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pb-1">
          <div className="container">
            <div className="row align-items-center py-lg-4">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="pe-lg-6">
                  <span className="text-primary ls-md text-uppercase fw-semibold">
                    OUR PRINCIPAL
                  </span>
                  <h2 className="display-5 mt-2 mb-3 fw-bold">
                    Meet Our Principal
                  </h2>
                  <h3>
                    Our Principal is a dedicated leader committed to fostering a
                    positive and inspiring school environment.
                  </h3>
                  <div className="mt-6">
                    <div className="d-flex mb-4">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          fill="currentColor"
                          className="bi bi-star-fill text-primary mt-1"
                          viewBox="0 0 16 16"
                        >
                          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                        </svg>
                      </div>
                      <div className="ms-3">
                        <h3 className="mb-2">Divine Mentorship</h3>
                        <p className="mb-0 fs-4">
                          Guided by spiritual leaders, Acharyakulam combines
                          spiritual wisdom with education.
                        </p>
                      </div>
                    </div>
                    <div className="d-flex mb-4">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          fill="currentColor"
                          className="bi bi-life-preserver mt-1 text-primary"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm6.43-5.228a7.025 7.025 0 0 1-3.658 3.658l-1.115-2.788a4.015 4.015 0 0 0 1.985-1.985l2.788 1.115zM5.228 14.43a7.025 7.025 0 0 1-3.658-3.658l2.788-1.115a4.015 4.015 0 0 0 1.985 1.985L5.228 14.43zm9.202-9.202-2.788 1.115a4.015 4.015 0 0 0-1.985-1.985l1.115-2.788a7.025 7.025 0 0 1 3.658 3.658zm-8.087-.87a4.015 4.015 0 0 0-1.985 1.985L1.57 5.228A7.025 7.025 0 0 1 5.228 1.57l1.115 2.788zM8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"></path>
                        </svg>
                      </div>
                      <div className="ms-3">
                        <h3 className="mb-2">Modern Meets Ancient</h3>
                        <p className="mb-0 fs-4">
                          Blending ancient wisdom with modern science for a
                          comprehensive learning experience.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="mt-4 mt-lg-0">
                  <img
                    src="/assets/images/principal.jpg"
                    alt="featured"
                    className="w-100 object-fit-cover rounded-5"
                    height="500"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container mt-1 pt-xl-4 pb-xl-8">
            <div className="row justify-content-center text-center pb-5 mb-3">
              <div className="col-md-8 col-12 mt-5">
                <span className="text-primary ls-md text-uppercase fw-semibold">
                  Our Events
                </span>
                <h2 className="display-4 mt-1">
                  Event <span className="colorful-text">Highlights</span>
                </h2>
              </div>
            </div>
            <div className="row align-items-center g-3">
              <div className="col-lg-4 col-md-6 col-12">
                <div>
                  <div
                    id="carouselevents1"
                    className="carousel slide carousel-fade"
                    data-bs-ride="carousel"
                  >
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <img
                          src="/assets/images/slider/3.jpg"
                          className="d-block w-100 rounded-5 object-fit-cover"
                          height="400"
                          alt="..."
                        />
                      </div>
                      <div className="carousel-item">
                        <img
                          src="/assets/images/slider/2.jpg"
                          className="d-block w-100 rounded-5 object-fit-cover"
                          height="400"
                          alt="..."
                        />
                      </div>
                    </div>
                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-bs-target="#carouselevents1"
                      data-bs-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                    </button>
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-bs-target="#carouselevents1"
                      data-bs-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div>
                  <div
                    id="carouselevents2"
                    className="carousel slide carousel-fade"
                    data-bs-ride="carousel"
                  >
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <img
                          src="/assets/images/photos/1.jpeg"
                          className="d-block w-100 rounded-5 object-fit-cover"
                          height="400"
                          alt="..."
                        />
                      </div>
                      <div className="carousel-item">
                        <img
                          src="/assets/images/slider/2.jpg"
                          className="d-block w-100 rounded-5 object-fit-cover"
                          height="400"
                          alt="..."
                        />
                      </div>
                      <div className="carousel-item">
                        <img
                          src="/assets/images/slider/4.jpeg"
                          className="d-block w-100 rounded-5 object-fit-cover"
                          height="400"
                          alt="..."
                        />
                      </div>
                    </div>
                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-bs-target="#carouselevents2"
                      data-bs-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                    </button>
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-bs-target="#carouselevents2"
                      data-bs-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div>
                  <div
                    id="carouselevents3"
                    className="carousel slide carousel-fade"
                    data-bs-ride="carousel"
                  >
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <img
                          src="/assets/images/slider/4.jpeg"
                          className="d-block w-100 rounded-5 object-fit-cover"
                          height="400"
                          alt="..."
                        />
                      </div>
                      <div className="carousel-item">
                        <img
                          src="/assets/images/slider/3.jpg"
                          className="d-block w-100 rounded-5 object-fit-cover"
                          height="400"
                          alt="..."
                        />
                      </div>
                    </div>
                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-bs-target="#carouselevents3"
                      data-bs-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                    </button>
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-bs-target="#carouselevents3"
                      data-bs-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-5">
          <div className="container">
            <div className="row align-items-center pb-lg-8">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="mb-4 mb-lg-0">
                  <img
                    src="/assets/images/photos/6.jpeg"
                    alt="featured"
                    className="w-100 rounded-5 object-fit-cover"
                    height="500"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12 mt-4 mt-lg-0">
                <div className="ps-lg-7">
                  <span className="text-primary ls-md text-uppercase fw-semibold">
                    OUR INFRASTRUCTURE
                  </span>
                  <h2 className="display-5 mt-4 mb-3 fw-bold">
                    Highlights of Our Campus Infrastructure
                  </h2>
                  <h3>
                    Explore the key features of our campus facilities,
                    showcasing the modern and well-equipped spaces
                  </h3>
                  <div className="mt-5 row">
                    <div className="col">
                      <ul className="list-unstyled fs-4 fw-medium">
                        <li className="mb-2 d-flex">
                          <i className="bi bi-check-circle-fill text-success me-2"></i>
                          <span>Library</span>
                        </li>
                        <li className="mb-2 d-flex">
                          <i className="bi bi-check-circle-fill text-success me-2"></i>
                          <span>Laboratories</span>
                        </li>
                        <li className="mb-2 d-flex">
                          <i className="bi bi-check-circle-fill text-success me-2"></i>
                          <span>Assembly Ground</span>
                        </li>
                      </ul>
                    </div>
                    <div className="col">
                      <ul className="list-unstyled fs-4 fw-medium">
                        <li className="mb-2 d-flex">
                          <i className="bi bi-check-circle-fill text-success me-2"></i>
                          <span>Art Room</span>
                        </li>
                        <li className="mb-2 d-flex">
                          <i className="bi bi-check-circle-fill text-success me-2"></i>
                          <span>Music Room</span>
                        </li>
                        <li className="mb-2 d-flex">
                          <i className="bi bi-check-circle-fill text-success me-2"></i>
                          <span>Labs</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pb-8 mb-4 overflow-hidden">
          <div className="container">
            <div className="row pb-5 mb-3">
              <div className="col-md-9 col-12 mt-4">
                <span className="text-primary ls-md text-uppercase fw-semibold">
                  Media
                </span>
                <h2 className="display-4 mt-1">
                  Our <span className="colorful-text">Media</span> Gallery
                </h2>
              </div>
            </div>
          </div>
          <div className="row g-3">
            <div className="col-lg-3 col-6">
              <div className="h-100">
                <img
                  src="./assets/images/photos/7.jpeg"
                  className="w-100 rounded-end-5 object-fit-cover"
                  height="500"
                />
              </div>
            </div>
            <div className="col-lg-6 col-6 d-lg-block d-none">
              <div className="h-100">
                <img
                  src="./assets/images/photos/4.jpeg"
                  className="w-100 rounded-5 object-fit-cover"
                  height="500"
                />
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className=" h-100">
                <img
                  src="./assets/images/photos/11.jpg"
                  className="w-100 rounded-start-5 object-fit-cover"
                  height="500"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="pb-8 mb-5 overflow-hidden">
          <div className="container">
            <div className="row justify-content-center text-center">
              <div className="col-md-9 col-12">
                <span className="text-primary ls-md text-uppercase fw-semibold">
                  Some FAQs
                </span>
                <h2 className="display-4 mt-3">
                  Frequently Asked{" "}
                  <span className="colorful-text">Questions</span>
                </h2>
                <p className="lead  px-lg-8 mb-8">
                  Get answers to common questions about our programs,
                  admissions, and school life.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 pb-lg-0 pb-5 d-flex align-items-center">
                <div
                  className="accordion accordion-flush w-100 pe-lg-5 pe-0 me-lg-5 me-0"
                  id="accordionFlushExample"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button px-0 bg-white"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="true"
                        aria-controls="flush-collapseOne"
                      >
                        <p className="faqs-text text-dark fw-medium m-0">
                          How do I enroll my child in school?
                        </p>
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      className="accordion-collapse collapse show"
                      data-bs-parent="#accordionFlushExample"
                      style={{}}
                    >
                      <div className="accordion-body px-0">
                        <p className="fs-4 fw-light mb-4 text-secondary">
                          Visit the admission section of our website for forms
                          and detailed instructions. You may also call the
                          office for assistance.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button px-0 bg-white collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwo"
                        aria-expanded="false"
                        aria-controls="flush-collapseTwo"
                      >
                        <p className="faqs-text text-dark fw-medium m-0">
                          Are extracurricular activities offered?
                        </p>
                      </button>
                    </h2>
                    <div
                      id="flush-collapseTwo"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                      style={{}}
                    >
                      <div className="accordion-body px-0">
                        <p className="fs-4 fw-light mb-4 text-secondary">
                          Yes, the school offers a variety of extracurricular
                          activities, including sports, arts, and clubs.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button px-0 bg-white collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseThree"
                        aria-expanded="false"
                        aria-controls="flush-collapseThree"
                      >
                        <p className="faqs-text text-dark fw-medium m-0">
                          Student discipline procedure?
                        </p>
                      </button>
                    </h2>
                    <div
                      id="flush-collapseThree"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                      style={{}}
                    >
                      <div className="accordion-body px-0">
                        <p className="fs-4 fw-light mb-4 text-secondary">
                          Discipline issues are addressed according to the
                          school’s code of conduct, with actions ranging from
                          warnings to suspension, depending on the severity.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button px-0 bg-white collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseFour"
                        aria-expanded="false"
                        aria-controls="flush-collapseFour"
                      >
                        <p className="faqs-text text-dark fw-medium m-0">
                          School’s attendance policy?
                        </p>
                      </button>
                    </h2>
                    <div
                      id="flush-collapseFour"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body px-0">
                        <p className="fs-4 fw-light mb-4 text-secondary">
                          Students are expected to attend all classes. Frequent
                          absences require a valid excuse and may impact
                          academic performance.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="mb-4 mb-lg-0">
                  <img
                    src="/assets/images/photos/9.jpg"
                    alt="featured"
                    className="w-100 rounded-5 object-fit-cover"
                    height="500"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Home;
