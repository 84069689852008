function MissionVision() {
  return (
    <>
      <section className="other-pages-hero-bg">
        <div className="container py-5">
          <div className="row py-5">
            <div className="col-xxl-6 offset-xxl-3 col-md-8 offset-md-2 col-12">
              <div className="text-center mb-3">
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-column gap-2">
                    <span className="text-dark fs-5">ACHARYAKULAM</span>
                  </div>
                  <h1 className="display-4 mb-0 lh-1">
                    Mission & Vision
                    <br />
                    <span className="text-primary">Intoduction</span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="pt-8">
        <div class="container mt-5">
          <div className="bg-light rounded-5 py-lg-5 py-10 mb-5 d-flex justify-content-center text-center">
            <div class="col-md-11 col-12 mt-5">
              <img src="https://cdn-icons-png.flaticon.com/512/3721/3721184.png" width="60" />
              <h2 class="display-5 my-3">Our Mission</h2>
              <p class="lead px-lg-8 mb-6">
                To groom fully awakened and fully competent souls who will build
                an awakened, enlightened, developed, family who in re-turn will
                enhance the physical, mental, intellectual, spiritual, social,
                economical, political and scientific society that will enrich
                the nation and then the world.
              </p>
            </div>
          </div>
        </div>
      </div>

      <section className="pt-8">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="row mb-8 align-items-center gy-4">
                <div className="col-md-6 offset-xxl-1 col-xxl-4 col-xl-5 offset-xl-1 mb-xxl-6">
                  <div className="d-flex flex-column gap-4">
                    <div className="border border-2 border-primary rounded-circle icon-shape icon-xl fs-3 text-primary">
                      1
                    </div>
                    <div className="d-flex flex-column gap-2">
                      <h3 className="mb-0 h2">
                        Training Vaidic Scholars for Leadership
                      </h3>
                      <p className="mb-0 fs-3">
                        To develop Vaidic scholars proficient in Grammar,
                        Philosophy, Vedas, Vaidic literature, Mathematics,
                        Indian history, and Raj Dharma, and to equip them for
                        leadership in social, spiritual, economic, scientific,
                        and political realms
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-xxl-6 col-xl-6 mb-xxl-6">
                  <div>
                    <img
                      src="/assets/images/slider/2.jpg"
                      alt=""
                      className="rounded-5 w-100 object-fit-cover object-position-left"
                      height="400"
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-8 align-items-center gy-4">
                <div className="col-md-6 col-xxl-5 offset-xxl-1 col-xl-5 offset-xl-1 order-md-1 order-2 mb-xxl-6">
                  <div>
                    <img
                      src="/assets/images/why.jpg"
                      alt=""
                      className="rounded-5 w-100 object-fit-cover object-position-left"
                      height="400"
                    />
                  </div>
                </div>
                <div className="col-md-6 col-xxl-4 offset-xxl-1 col-xl-5 offset-xl-1 order-xl-2 order-1 mb-xxl-6">
                  <div className="d-flex flex-column gap-4">
                    <div className="border border-2 border-primary rounded-circle icon-shape icon-xl fs-3 text-primary">
                      2
                    </div>
                    <div className="d-flex flex-column gap-2">
                      <h3 className="mb-0 h2">
                        Shaping Leaders for Diverse Fields
                      </h3>
                      <p className="mb-0 fs-3">
                        Preparing leaders of modern Bharata to excel in diverse
                        fields such as Technology, Management, Defence, and
                        more. Our goal is to cultivate intellectuals who set new
                        benchmarks across various disciplines.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-8 align-items-center gy-4">
                <div className="col-md-6 offset-xxl-1 col-xxl-4 col-xl-5 offset-xl-1 mb-xxl-6">
                  <div className="d-flex flex-column gap-4">
                    <div className="border border-2 border-primary rounded-circle icon-shape icon-xl fs-3 text-primary">
                      3
                    </div>
                    <div className="d-flex flex-column gap-2">
                      <h3 className="mb-0 h2">Knowledge Aspect</h3>
                      <p className="mb-0 fs-3">
                        To enhance intellectual development of a child is our
                        primary goal.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-xxl-6 col-xl-6 mb-xxl-6">
                  <div>
                    <img
                      src="/assets/images/slider/2.jpg"
                      alt=""
                      className="rounded-5 w-100 object-fit-cover object-position-left"
                      height="400"
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-8 align-items-center gy-4">
                <div className="col-md-6 col-xxl-5 offset-xxl-1 col-xl-5 offset-xl-1 order-md-1 order-2 mb-xxl-6">
                  <div>
                    <img
                      src="/assets/images/photos/1.jpeg"
                      alt=""
                      className="rounded-5 w-100 object-fit-cover object-position-left"
                      height="400"
                    />
                  </div>
                </div>
                <div className="col-md-6 col-xxl-4 offset-xxl-1 col-xl-5 offset-xl-1 order-xl-2 order-1 mb-xxl-6">
                  <div className="d-flex flex-column gap-4">
                    <div className="border border-2 border-primary rounded-circle icon-shape icon-xl fs-3 text-primary">
                      4
                    </div>
                    <div className="d-flex flex-column gap-2">
                      <h3 className="mb-0 h2">Behaviour Aspect</h3>
                      <p className="mb-0 fs-3">
                        At Acharyakulam, we aim to eliminate both prominent and
                        latent vices in children. By fostering high moral
                        standards and using practices like yoga and devotion, we
                        strive to uphold integrity and overcome unethical
                        tendencies.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="pb-8">
        <div class="container mb-5">
          <div className="bg-light rounded-5 py-lg-5 py-10 mb-5 d-flex justify-content-center text-center">
            <div class="col-md-11 col-12 mt-5">
            <img src="https://cdn-icons-png.flaticon.com/512/2493/2493945.png" width="60" />
              <h2 class="display-5 my-3">Our Vision</h2>
              <p class="lead px-lg-8 mb-6">
                Our vision is to develop Vaidic scholars in Grammar, Veda,
                Philosophy & Dharma, equipping them for leadership in all
                spheres. We aim to create modern Indian leaders who set new
                benchmarks, integrating traditional wisdom with contemporary
                education to enlighten students physically, intellectually, and
                spiritually.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MissionVision;
