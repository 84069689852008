function WhyAcharyakulam() {
  return (
    <>
      <section className="other-pages-hero-bg">
        <div className="container py-5">
          <div className="row py-5">
            <div className="col-xxl-6 offset-xxl-3 col-md-8 offset-md-2 col-12">
              <div className="text-center mb-3">
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-column gap-2">
                    <span className="text-dark fs-5">ACHARYAKULAM</span>
                  </div>
                  <h1 className="display-4 mb-0 lh-1">
                    Why Acharyakulam?
                    <br />
                    <span className="text-primary">Intoduction</span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-6">
        <div className="container">
          <div className="row align-items-center py-lg-8">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="pe-lg-6">
                <h2 className="display-4 mt-4 mb-3 fw-bold">
                  Why Acharyakulam?
                </h2>
                <h3>
                  Acharyakulam merges spiritual guidance with cutting-edge
                  education.
                </h3>
                <div className="mt-6">
                  <p className="mb-0 fs-4">
                    Which the divine blessings & guidance of Param Pujya
                    Yogrishi Swami Ramdevji and Dr. Acharya Balkrishnaji, an
                    innovative vision in the field of value-based
                    education-Acharyakulam Haridwar was inaugurated on 26th of
                    April, 2013. <br />
                    <br /> Acharyakulam blends the timeless wisdom of ancient
                    Gurukuls with today's advanced scientific and IT-enabled
                    education.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="mt-4 mt-lg-0">
                <img
                  src="/assets/images/school.jpg"
                  alt="featured"
                  className="w-100 object-fit-cover rounded-5"
                  height="500"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="pb-8">
        <div class="container">
          <div className="bg-light rounded-5 py-lg-8 py-10 mb-5 d-flex justify-content-center text-center">
            <div class="col-md-10 col-12 mt-5">
              <span className="text-primary ls-md text-uppercase fw-semibold">
                ACHARYAKULAM
              </span>
              <h2 class="display-4 my-3">
                From Inception to Expansion in Ranchi
              </h2>
              <p class="lead px-lg-8 mb-6">
                In April 2019, Acharyakulam opened in Ranchi with over 600
                students. Since then, it has rapidly grown, now serving 1,900+
                students from LKG to IX and reflecting the region's growing
                interest in Vaidic and modern education.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WhyAcharyakulam;
