function AdmissionProcedure() {
  return (
    <>
      <section className="other-pages-hero-bg">
        <div className="container py-5">
          <div className="row py-5">
            <div className="col-xxl-6 offset-xxl-3 col-md-8 offset-md-2 col-12">
              <div className="text-center mb-3">
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-column gap-2">
                    <span className="text-dark fs-5">ACHARYAKULAM</span>
                  </div>
                  <h1 className="display-4 mb-0 lh-1">
                    Admission Procedure
                    <br />
                    <span className="text-primary">Introduction</span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-8 bg-white">
        <div className="container mt-5">
          <span className="text-uppercase text-primary fw-semibold ls-md">
            Admission
          </span>
          <div className="row">
            <div className="col-12">
              <h1 className="display-5 mt-2 mb-3">Admission Criteria</h1>
              <p className="fs-4">
                Admission at Acharyakulam is based on the availability of seats
                and the interest and aptitude of the child towards Special
                Vaidic and Sanskrit Education that would be imparted along with
                full fledged modern CBSE/NCERT pattern. The students who show
                Interest, Inclination and Ability to study Special Vaidic and
                Sanskrit Education would preferably be invited to apply for
                admission at Acharyakulam
              </p>
            </div>
            <div className="col-12">
              <h1 className="display-5 mt-2 mb-3">Admission Rules</h1>
              <p className="fs-3 mb-0 text-dark">Admission Class</p>
              <p className="fs-4">
                Admission is taken in class Nursery to VIII.
              </p>
              <p className="fs-3 mb-0 text-dark">Eligibility Criteria</p>
              <p className="fs-4">
                Passed or studying in the Class in the year preceding the
                admission year
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-8 bg-white">
        <div className="container my-4">
          <span className="text-uppercase text-primary fw-semibold ls-md">
            Documents List
          </span>
          <div className="row">
            <div className="col-12">
              <h1 className="display-5 mt-2 mb-3">
                List of Documents to be brought at the time of Admission
              </h1>
              <p className="fs-4">
                <ul>
                  <li>
                    Birth Certificate issued by Municipal Corporation of the
                    State Government.
                  </li>
                  <li>
                    Only following Proof of Residence (in the name of the
                    child’s Father or Mother) accepted as Address proof of the
                    Child (No other address proof will be accepted) -Unique
                    Identification Card (Aadhar Card) / Voter ID Card /
                    Post-paid Mobile Bill / Electricity Bill /Passport / Gas
                    connection / Ration Card (having mother and father name
                    along with name of child).
                  </li>
                  <li>
                    If the sibling(s) are studying in the same school, photo
                    copy of the ID card issued by the school office for the
                    Academic Session 2023/24. Only real brother/ sister studying
                    in the school will be considered as sibling.
                  </li>
                  <li>
                    Medical fitness certificate and blood group report certified
                    by medical practitioner of the student is mandatory.
                  </li>
                  <li>
                    In case of applicants with Special Needs, a copy of
                    certificate of Disability from a Medical Officer of Govt.
                    Hospital should be submitted along with the registration
                    form.
                  </li>
                  <li>
                    Appropriate documentation in case of Single Parent
                    (presently running divorce case in the court will not be
                    considered, either widow/widower or divorced would be
                    considered). Appropriate documentation in case of change in
                    the name of mother/ father after marriage, divorce, adoption
                    etc.
                  </li>
                  <li>
                    ID proof of Father, Mother, Guardian and Emergency contact
                    person is mandatory.
                  </li>
                  <li>
                    Transfer Certificate (Original) of the previous school.
                  </li>
                  <li>Report Card of the previous school.</li>
                  <li>
                    Two passport size photographs of the child and one
                    photograph of each parents (father, mother and guardian)
                  </li>
                </ul>

                <div className="mt-5 text-danger">
                  Note - Parents Are Required To Submit Xerox Copy Of The
                  Following Documents Along With The Admission Form Duly Filled
                  Properly. (Overwriting And Cutting Form Will Not Be
                  Acceptable).
                </div>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AdmissionProcedure;
