import { BrowserRouter, Route, Routes } from "react-router-dom";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Home from "./pages/Home";
import Header from "./pages/Header";
import Footer from "./pages/Footer";
import WhyAcharyakulam from "./pages/WhyAcharyakulam";
import MissionVision from "./pages/MissionVision";
import SwamiRamdevJi from "./pages/SwamiRamdevJi";
import AcharyaBalkrishnaJi from "./pages/AcharyaBalkrishnaJi";
import Principal from "./pages/Principal";
import Career from "./pages/Career";
import Contact from "./pages/Contact";
import MandatoryDisclosure from "./pages/MandatoryDisclosure";
import AdmissionProcedure from "./pages/AdmissionProcedure";
import StudentCouncil from "./pages/StudentCouncil";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <div className="d-xl-block d-none">
      <BrowserRouter>
        <Header />
        <ScrollToTop />
        <Routes>
          <Route path="" element={<Home />} />
          <Route path="why-acharyakulam" element={<WhyAcharyakulam />} />
          <Route path="mission-vision" element={<MissionVision />} />
          <Route path="swami-ramdev-ji" element={<SwamiRamdevJi />} />
          <Route
            path="acharya-balkrishna-ji"
            element={<AcharyaBalkrishnaJi />}
          />
          <Route path="principal" element={<Principal />} />
          <Route path="career" element={<Career />} />
          <Route path="contact" element={<Contact />} />
          <Route
            path="mandatory-disclosure"
            element={<MandatoryDisclosure />}
          />
          <Route path="admission-procedure" element={<AdmissionProcedure />} />
          <Route path="student-council" element={<StudentCouncil />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
